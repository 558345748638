import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { apiCall } from '../api';
import DeleteIcon from '../assets/icons/delete.svg';

function CustomUI(props) {
    return (
      <div className="popup__overlay">
        <h1>{props.title}</h1>
        <p>{props.message}</p>
        <button onClick={props.onClose}>No</button>
        <button onClick={props.onYes}>Yes</button>
      </div>
    );
}

const Quizzes = ({ userData, setUserData, setToken, token }) => {
    const navigate = useNavigate();
    const [quizzes, setQuizzes] = useState([]);

    useEffect(() => {
        setQuizzes(userData.user.quizzes);
    }, [userData]);

    const navigateToQuiz = async (id, newToken) => {
        const toastId = toast.loading("Loading...");

        const data = {
            token: newToken || token
        };

        const response = await apiCall('GET', `/quiz?id=${id}`, data);

        toast.dismiss(toastId);

        if (response?.status === 200) {
            setToken(response.token);

            const { quiz } = response.user;

            if (quiz.published) {
                return navigate('/preview_quiz', {
                    state: {
                        quiz,
                    }
                });
            }

            return navigate('/edit_quiz', {
                state: {
                    quiz,
                }
            });

        } else if (response?.status === 403) {
            const response = await apiCall('GET', '/refresh');

            if (response?.status === 200 || response?.status === 201) {
                const user = {
                    user: response.user,
                    token: response.token
                }
                await setUserData(user);
                await setToken(response.token);

                navigateToQuiz(id, response.token);
            } else if (response?.status === 500) {
                toast.error('Something went wrong, please try again later.');
            } else {
                toast.error('You are not authenticated.');
                await apiCall('DELETE', '/logout');
                return navigate('/');
            }

        } else if (response?.status === 500) {
            toast.error('Something went wrong, please try again later.');
        } else {
            toast.error('You are not authenticated.');
            await apiCall('DELETE', '/logout');
            return navigate('/');
        }
    }

    const deleteAlert = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <CustomUI
                    title={'Delete quiz'}
                    message={'Are you sure you want to do this?'}
                    onClose={onClose}
                    onYes={() => {
                        deleteQuiz(id);
                        onClose();
                    }}
                    />          
              );
            }
          });
    }

    const deleteQuiz = async (id, newToken) => {
        const toastId = toast.loading("Loading...");

        const data = {
            token: newToken || token
        };

        const response = await apiCall('DELETE', `/quiz?id=${id}`, data);

        toast.dismiss(toastId);

        if (response?.status === 201) {
            const user = {
                user: response.user,
                token: response.token
            }
            setUserData(user);
            setToken(response.token);

            toast.success("Quiz deleted successfully");
        } else if (response?.status === 403) {
            const response = await apiCall('GET', '/refresh');

            if (response?.status === 200 || response?.status === 201) {
                const user = {
                    user: response.user,
                    token: response.token
                }
                await setUserData(user);
                await setToken(response.token);
                
                deleteQuiz(id, response.token);
            } else if (response?.status === 500) {
                toast.error('Something went wrong, please try again later.');
            } else {
                toast.error('You are not authenticated.');
                setUserData(null);
                setToken(null);
                await apiCall('DELETE', '/logout');
                return navigate('/');
            }

        } else if (response?.status === 500) {
            toast.error('Something went wrong, please try again later.');
        } else {
            toast.error('You are not authenticated.');
            setUserData(null);
            setToken(null);
            await apiCall('DELETE', '/logout');
            return navigate('/');
        }
    }
    
    return (
        <div className="quizzes__main">
            <div className="quizzes__container">
                <h2>Click on a quiz to view questions.</h2>
                {quizzes?.length > 0 ? 
                    (
                        quizzes.map((quiz, i) => {
                            return (
                                <div key={i}  className="quiz_main__container">
                                    <div onClick={() => navigateToQuiz(quiz._id)} className="quiz__container">
                                        <h3>{quiz.title}</h3>
                                        <h5>{quiz.numberOfQuestions} question{quiz.numberOfQuestions > 1 ? "s" : null}</h5>
                                    </div>
                                    <img src={DeleteIcon} alt={"delete"} className="quiz_del__btn"onClick={() => deleteAlert(quiz._id)}/>
                                </div>
                            )
                    })
                ) : (
                    <>
                        <h3>You have no quizzes yet.</h3>
                    </>
                )}
                <button
                    type="submit"
                    className="dashboard__btn"
                    onClick={(e) => navigate('/create_quiz')}>Create Quiz</button>
                <button
                    type="submit"
                    className="dashboard__btn"
                    onClick={(e) => navigate('/dashboard')}>Back to Dashboard</button>
            </div>
            <Toaster />
        </div>
    )
}

export default Quizzes;
