import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { apiCall } from '../api/index';

const Quiz = () => {
    const [title, setTitle] = useState('');
    const [questions, setQuestions] = useState([]);
    const [isResults, setIsResults] = useState(false);
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const toastId = toast.loading("Loading...");

        // get the requested quiz from the link provided
        // if not found navigate to the main page
        const getQuiz = async (link) => {
            const response = await apiCall('GET', `/published_quiz?link=${link}`);

            if (response?.status === 200) {
                let { quiz } = response;
                quiz.questions.forEach(q => q.answers.forEach(a => a.checked = false));
                setTitle(quiz.title);
                setQuestions(quiz.questions);
            } else {
                navigate("/dashboard");
            }
            toast.dismiss(toastId);
        }
        if (location.pathname !== '/' && location.pathname !== '') {
            let link = location.pathname.slice(1);
            getQuiz(link);
        } else {
            toast.dismiss(toastId);
            navigate("/dashboard");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateCorrectAnswer = (idx, id) => {
        let qs = [...questions];
        let type = qs[idx].type;

        if (type === "single") {
            qs[idx].answers.filter((a, i) => i !== id).forEach(a => a.checked = false);
        }

        qs[idx].answers[id].checked = !qs[idx].answers[id].checked;

        setQuestions(qs);
    }

    const checkAnswers = () => {
        let correctAnswers = 0;
        for (let i = 0; i < questions.length; i++) {
            let q = questions[i];
            for (let j = 0; j < q.answers.length; j++) {
                let a = q.answers[j];

                if (a.correct !== a.checked) {
                    break;
                }
                if (j === q.answers.length - 1) {
                    correctAnswers++;
                }
            }
        }
        setCorrectAnswers(correctAnswers);
        setIsResults(true);

        if (correctAnswers >= questions.length / 2) {
            toast.success(`You got ${correctAnswers} / ${questions.length} correct answers`);
        } else {
            toast(`You got ${correctAnswers} / ${questions.length} correct answers`);
        }
    }

    const content = (
        <div className="create_quiz__container">
            <h2>Choose the correct answers!</h2>
            <div className="title__preview">{title}</div>
            
            {questions.length > 0 && questions.map((q, idx) => {
                return (
                    <div className="questions__preview__container" key={idx}>
                        <div className="questions__top">
                            <h4>Question #{idx + 1}</h4>
                        </div>
                        <div className="question__preview">{q.question}</div>
                        <div className="question__preview__type">{q.type === 'multi' ? "This question has multiple correct answers" : "This question has only 1 correct answer"}</div>
                        <h4>Answers:</h4>
                        {q.answers.length > 0 && q.answers.map((a, id) => {
                            return (
                                <div
                                    className="answer__preview__container"
                                    key={id}>
                                    <div className="answer__preview">{a.answer}</div>
                                    <input type="checkbox" checked={a.checked} onChange={(e) => null} onClick={() => updateCorrectAnswer(idx, id)} id={id} />
                                </div>
                            )
                        })}
                    </div>
                )
            })}

            <button
                type="submit"
                className="dashboard__btn"
                onClick={(e) => checkAnswers(e)}>Submit Answers</button>
        </div>
    )

    const results = (
        <div className="preview_quiz_results__container">
            <h3>{`You got ${correctAnswers} / ${questions.length} correct answers`}</h3>
            <div>
                <div className="title__preview">{title}</div>
                
                {questions.length > 0 && questions.map((q, idx) => {
                    return (
                        <div className="questions__preview__container" key={idx}>
                            <div className="questions__top">
                                <h4>Question #{idx + 1}</h4>
                            </div>
                            <div className="question__preview">{q.question}</div>
                            <div className="question__preview__type">{q.type === 'multi' ? "This question has multiple correct answers" : "This question has only 1 correct answer"}</div>
                            <h4>Answers:</h4>
                            {q.answers.length > 0 && q.answers.map((a, id) => {
                                return (
                                    <div
                                        className="answer__preview__container"
                                        key={id}>
                                        <div className="answer__preview">{a.answer}</div>
                                        <input type="checkbox" results={a.checked === true && a.correct === false ? "wrong" : a.correct && a.checked ? "correct" : ""} checked={a.correct} onChange={(e) => null} onClick={null} id={id} />
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            
            </div>
            <button
                type="submit"
                className="dashboard__btn"
                onClick={() => window.location.reload()}>Take Quiz Again</button>
            <button
                type="submit"
                className="dashboard__btn"
                onClick={() => navigate('/dashboard')}>Create Your Own Quiz</button>
        </div>
    )

    return (
        <div className="create_quiz__main">
            {!isResults ? content : results}
            <Toaster />
        </div>
    )
}

export default Quiz;
