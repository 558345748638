import { useState } from 'react';
import Login from './Login';
import SignUp from './SignUp';

const Public = ({ setUserData, setToken, loading }) => {
  const [isLogIn, setIsLogIn] = useState(true);

  return (
    <div className="app">

      <div className="auth__container">
        {loading ? 
        (<p>Loading...</p>)
        : 
        (<div>
          <p>Login or Sign Up to start creating quizzes!</p>
          {isLogIn ? (
            <Login setIsLogIn={setIsLogIn} setUserData={setUserData} setToken={setToken} />
          ) : (
            <SignUp setIsLogIn={setIsLogIn} setUserData={setUserData} setToken={setToken} />
          )}
        </div>)}
      </div>
        
    </div>
  );
}

export default Public;