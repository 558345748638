import { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { apiCall } from '../api';
import DropdownIcon from '../assets/icons/down-arrow.svg';
import DeleteIcon from '../assets/icons/delete.svg';

const CreateQuiz = ({ userData, setUserData, setToken, token }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const titleRef = useRef();
    const [title, setTitle] = useState('');
    const [questions, setQuestions] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [quizId, setQuizId] = useState();

    useEffect(() => {
        // check if quiz has already been created to be edited
        if (location.state) {
            let { quiz } = location.state;
            let { questions, _id } = quiz;

            for (let i = 0; i < questions.length; i++) {
                let { type } = questions[i];

                if (type === "multi") {
                    questions[i].type = "Multiple Answers";
                } else if (type === "single") {
                    questions[i].type = "Single Answer";
                }
            }

            setQuizId(_id);
            setTitle(quiz.title);
            setQuestions(questions);
            setIsEditing(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    // toggle question type dropdown
    const toggleDropdown = (e, idx) => {
        e.stopPropagation();
        let qs = [...questions];
        qs.filter(q => q.idx !== idx).forEach(q => q.showDropdown = false);
        qs[idx].showDropdown = !qs[idx].showDropdown;
        
        setQuestions(qs);
    }

    const hideAllDropdowns = (e) => {
        let qs = [...questions];
        qs.forEach(q => q.showDropdown = false);
        
        setQuestions(qs);
    }

    const updateQuestionType = (type, idx) => {
        let qs = [...questions];
        qs[idx].type = type;

        if (type === "Multiple Answers" && qs[idx].numberOfAnswers < 3) {
            addAnswer(idx);
        }

        setQuestions(qs);
    }

    const updateQuestion = (question, idx) => {
        let qs = [...questions];
        qs[idx].question = question;

        setQuestions(qs);
    }

    const addQuestion = () => {
        if (questions.length === 10) {
            return;
        }

        questions.push({
            idx: questions.length,
            type: "",
            question: "",
            showDropdown: false,
            numberOfAnswers: 2,
            answers: [
                {
                    idx: 0,
                    answer: "",
                    correct: false,
                },
                {
                    idx: 1,
                    answer: "",
                    correct: false,
                },
            ]
        });

        setQuestions(questions);
    }

    const deleteQuestion = (idx) => {
        questions.splice(idx, 1);
        
        questions.forEach((q, id) => q.idx = id);
        setQuestions(questions);
    }

    const addAnswer = (idx) => {
        let qs = [...questions];

        let { numberOfAnswers } = qs[idx];

        if (numberOfAnswers >= 5) {
            return;
        }

        qs[idx].numberOfAnswers++;
        qs[idx].answers.push({
            idx: numberOfAnswers,
            answer: "",
            correct: false,
        });

        setQuestions(qs);
    }

    const updateAnswer = (answer, idx, id) => {
        let qs = [...questions];
        qs[idx].answers[id].answer = answer;

        setQuestions(qs);
    }

    const updateCorrectAnswer = (e, idx, id) => {
        e.stopPropagation();

        let qs = [...questions];
        let type = qs[idx].type;

        // check if the question type is single or multiple and update accordingly
        if (!type) {
            return toast.error("Choose Question Type.");
        } else if (type === "Single Answer") {
            // make sure only one answer is correct
            qs[idx].answers.filter(a => a.idx !== id).forEach(a => a.correct = false);
            qs[idx].answers[id].correct = !qs[idx].answers[id].correct;
        } else {
            qs[idx].answers[id].correct = !qs[idx].answers[id].correct;
        }

        setQuestions(qs);
    }

    const deleteAnswer = (e, idx, id) => {
        e.stopPropagation();

        let qs = [...questions];

        let { numberOfAnswers } = qs[idx];

        let type = qs[idx].type;

        // make sure number of answers corresponds to each question type
        if (type === "Single Answer" && numberOfAnswers <= 2) {
            return toast.error("This question must have at least 2 answers.");
        } else if (type === "Multiple Answers" && numberOfAnswers <= 3) {
            return toast.error("This questions must have at least 3 answers.");
        } else if (!type) {
            return toast.error("This questions must have at least 2 answers.");
        }

        qs[idx].answers.splice(id, 1);

        qs[idx].answers.forEach((a, i) => a.idx = i);

        qs[idx].numberOfAnswers--;

        setQuestions(qs);
    }

    // while updating quiz instead of going through the hassle
    // of checking each question and answer for update just
    // delete the old quiz data and then create a new one
    const deleteQuiz = async (id, newToken) => {
        const data = {
            token: newToken || token
        };

        const response = await apiCall('DELETE', `/quiz?id=${id}`, data);

        if (response?.status === 201) {
            const user = {
                user: response.user,
                token: response.token
            }
            setUserData(user);
            setToken(response.token);
        } else if (response?.status === 403) {
            const response = await apiCall('GET', '/refresh');

            if (response?.status === 200 || response?.status === 201) {
                const user = {
                    user: response.user,
                    token: response.token
                }
                await setUserData(user);
                await setToken(response.token);
                
                deleteQuiz(id, response.token);
            } else if (response?.status === 500) {
                toast.error('Something went wrong, please try again later.');
            } else {
                toast.error('You are not authenticated.');
                await apiCall('DELETE', '/logout');
                return navigate('/');
            }

        } else if (response?.status === 500) {
            toast.error('Something went wrong, please try again later.');
        } else {
            toast.error('You are not authenticated.');
            await apiCall('DELETE', '/logout');
            return navigate('/');
        }
    }

    // do all necessary checks before submitting quiz
    const submitQuiz = async (publish, newToken) => {
        if (!title) {
            return toast.error('Quiz must have a title.');
        }

        for (let i = 0; i < questions.length; i++) {
            let q = questions[i];
            if (!q.type) {
                return toast.error(`Question #${i + 1} doesn't have a type.`);
            } else if (!q.question) {
                return toast.error(`Question #${i + 1} is empty.`);
            }
            let type = q.type;
            let correctCounter = 0;

            for (let j = 0; j < q.answers.length; j++) {
                let a = q.answers[j];
                if (!a.answer) {
                    return toast.error(`Question #${i + 1} / Answer #${j + 1} is empty.`);
                }
                correctCounter += a.correct ? 1 : 0;
            }

            if (type === "Multiple Answers" && correctCounter < 2) {
                return toast.error(`Question #${i + 1} doesn't have enough correct answers.`);
            } else if (type === "Single Answer" && correctCounter !== 1) {
                return toast.error(`Question #${i + 1} must have 1 correct answer.`);
            }
        }

        const toastId = toast.loading("Saving...");

        questions.forEach(q => q.type = q.type === "Multiple Answers" || q.type === "multi" ? "multi" : "single");

        // if the request is to update quiz delete the old quiz then create a new one
        if (isEditing) {
            await deleteQuiz(quizId);
        }

        const data = {
            title,
            numberOfQuestions: questions.length,
            questions,
        };

        const body = {
            data,
            token: newToken || userData.token
        };

        // post request to api
        const response = await apiCall('POST', publish ? '/quiz/publish' : '/quiz/add', body);

        toast.dismiss(toastId);

        // check for errors
        if (response?.status === 201) {
            const user = {
                user: response.user,
                token: response.token
            }
            
            await setUserData(user);
            await setToken(response.token);

            return navigate('/dashboard');
        } else if (response?.status === 403) {
            const response = await apiCall('GET', '/refresh');

            if (response?.status === 200 || response?.status === 201) {
                const user = {
                  user: response.user,
                  token: response.token
                }
                await setUserData(user);
                await setToken(response.token);

                submitQuiz(publish, response.token);
            } else if (response?.status === 500) {
                toast.error('Something went wrong, please try again later.');
            } else {
                toast.error('You are not authenticated.');
                await apiCall('DELETE', '/logout');
                return navigate('/');
            }

        } else if (response?.status === 500) {
            toast.error('Something went wrong, please try again later.');
        } else {
            toast.error('You are not authenticated.');
            await apiCall('DELETE', '/logout');
            return navigate('/');
        }
    }

    return (
        <div className="create_quiz__main">
            <div
                onClick={(e) => hideAllDropdowns(e)}
                className="create_quiz__container">
                <h2>Let's create a quiz!</h2>
                <input
                    ref={titleRef}
                    type="text"
                    className="title__input"
                    placeholder="Quiz Title"
                    value={title}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => setTitle(e.target.value)}
                    />
                
                {questions.length > 0 && questions.map((q, idx) => {
                    return (
                        <div className="questions__container" key={idx}>
                            <div className="questions__top">
                                <h4>Question #{idx + 1}</h4>
                                <button onClick={() => deleteQuestion(idx)}>Delete Question</button>
                            </div>
                            <div className="dropdown">
                                <div
                                    onClick={(e) => toggleDropdown(e, idx)}
                                    className={!q.type ? "dropdown__container dropdown__container__empty" : q.showDropdown ? "dropdown__container dropdown__container__focus" : "dropdown__container"}
                                    >{q.type || "Choose Question Type"}<img alt="arrow" src={DropdownIcon}/></div>
                                
                                {q.showDropdown && (
                                    <div className='dropdown__elements'>
                                        <div onClick={() => updateQuestionType('Single Answer', idx)}>Single Answer</div>
                                        <div onClick={() => updateQuestionType('Multiple Answers', idx)}>Multiple Answers</div>
                                    </div>
                                )}
                            </div>
                            <input
                                className="question__input"
                                type="text"
                                placeholder={`Question #${idx + 1}`}
                                value={q.question}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => updateQuestion(e.target.value, idx)}
                                />
                            <h4>Answers:</h4>
                            {q.answers.length > 0 && q.answers.map((a, id) => {
                                return (
                                    <div
                                        className="answer__container"
                                        key={id}>
                                        <input
                                            className="answer__input"
                                            type="text"
                                            placeholder={`Answer #${id + 1}`}
                                            value={a.answer}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={(e) => updateAnswer(e.target.value, idx, id)}
                                            />
                                        <input type="checkbox" checked={a.correct} onChange={(e) => null} onClick={(e) => updateCorrectAnswer(e, idx, id)} id={id} />
                                        <img src={DeleteIcon} alt={"delete"} className="answer_del__btn" onClick={(e) => deleteAnswer(e, idx, id)}/>
                                    </div>
                                )
                            })}
                            <button
                                type="submit"
                                className={q.numberOfAnswers >= 5 ? "add_question__btn__disabled" : "add_question__btn"}
                                disabled={q.numberOfAnswers >= 5}
                                onClick={() => addAnswer(idx)}>Add Answer</button>
                        </div>
                    )
                })}

                <button
                    type="submit"
                    className={questions.length >= 10 ? "add_question__btn__disabled" : "add_question__btn"}
                    disabled={questions.length >= 10}
                    onClick={addQuestion}>Add Question</button>

                <button
                    type="submit"
                    className={questions.length <= 0 ? "submit_quiz__btn__disabled" : "submit_quiz__btn"}
                    disabled={questions.length <= 0}
                    onClick={() => submitQuiz(false)}>Save Quiz</button>

                <button
                    type="submit"
                    className={questions.length <= 0 ? "publish_quiz__btn__disabled" : "publish_quiz__btn"}
                    disabled={questions.length <= 0}
                    onClick={() => submitQuiz(true)}>Save and Publish Quiz</button>

                <button
                    type="submit"
                    className="dashboard__btn"
                    onClick={(e) => navigate('/dashboard')}>Back to Dashboard</button>
            </div>
            <Toaster />
        </div>
    )
}

export default CreateQuiz;
