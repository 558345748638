import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from './components/Layout';
import Public from './components/Public';
import RequireAuth from './components/RequireAuth';
import Dashboard from './components/Dashboard';
import CreateQuiz from './components/CreateQuiz';
import Quizzes from './components/Quizzes';
import PreviewQuiz from './components/PreviewQuiz';
import Quiz from './components/Quiz';
import { apiCall } from './api';

function App() {
  const [token, setToken] = useState('');
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // check if user is authenticated or if token has expired
  // request a new token using the refresh token cookie if it's there
  const checkAuth = async () => {
    if (token) {
      const response = await apiCall('GET', '/auth', {token});
      
      if (response?.status === 200 || response?.status === 201) {
        const user = {
          user: response.user,
          token: response.token
        }
        setUserData(user);
        setToken(response.token);
        setLoading(false);
        
        const path = location.pathname !== '/' && location.pathname !== '' ? location.pathname : '/dashboard';
        
        return navigate(path);
      }
    }
    
    const response = await apiCall('GET', '/refresh');
    
    if (response?.status === 201) {
      const user = {
        user: response.user,
        token: response.token
      }
      await setUserData(user);
      await setToken(response.token);
      await setLoading(false);

      const path = location.pathname !== '/' && location.pathname !== '' ? location.pathname : '/dashboard';

      return navigate(path);
    }

    setLoading(false);
  }

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route
          index
          element={<Public
                      loading={loading}
                      setToken={setToken}
                      setUserData={setUserData}
                      />}
          />
        <Route path=":link" element={<Quiz />} />

        {/* protected routes */}
        <Route element={<RequireAuth token={token} />}>
          <Route path="dashboard" element={<Dashboard userData={userData} />} />
          <Route path="create_quiz" element={<CreateQuiz userData={userData} setUserData={setUserData} setToken={setToken} token={token} />} />
          <Route path="edit_quiz" element={<CreateQuiz userData={userData} setUserData={setUserData} setToken={setToken} token={token} />} />
          <Route path="quizzes" element={<Quizzes token={token} userData={userData} setUserData={setUserData} setToken={setToken} />} />
          <Route path="preview_quiz" element={<PreviewQuiz userData={userData} />} />
        </Route>

      </Route>
    </Routes>
  )
}

export default App;
