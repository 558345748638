import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { BASE_URL } from '../api/index';

const PreviewQuiz = () => {
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [questions, setQuestions] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.state) {
            let { quiz } = location.state;
            let { questions } = quiz;

            setTitle(quiz.title);
            setLink(quiz.link);
            setQuestions(questions);
        } else {
            navigate("/quizzes");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(`${BASE_URL + link}`);
        toast.success("Link copied to clipboard. You can send it to your friends");
    }

    return (
        <div className="create_quiz__main">
            <div className="create_quiz__container">
                <h4>This quiz is published it can't be edited!</h4>
                <div className="title__preview">{title}</div>
                
                {questions.length > 0 && questions.map((q, idx) => {
                    return (
                        <div className="questions__preview__container" key={idx}>
                            <div className="questions__top">
                                <h4>Question #{idx + 1}</h4>
                            </div>
                            <div className="question__preview">{q.question}</div>
                            <h4>Answers:</h4>
                            {q.answers.length > 0 && q.answers.map((a, id) => {
                                return (
                                    <div
                                        className="answer__preview__container"
                                        key={id}>
                                        <div className="answer__preview">{a.answer}</div>
                                        <input type="checkbox" checked={a.correct} onChange={(e) => null} onClick={(e) => null} id={id} />
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}

                <div onClick={() => copyToClipboard()} className="link__preview">
                    <h5>Click to copy the link to send it to your friends:</h5>
                    <a onClick={(e) => e.stopPropagation()}href={`/${link}`} rel="noreferrer" target="_blank">{`${BASE_URL + link}`}</a>
                </div>

                <button
                    type="submit"
                    className="dashboard__btn"
                    onClick={(e) => navigate('/dashboard')}>Back to Dashboard</button>
            </div>
            <Toaster />
        </div>
    )
}

export default PreviewQuiz;
