import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { apiCall } from '../api';

const Login = ({ setIsLogIn, setUserData, setToken }) => {
    const emailRef = useRef();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        emailRef.current.focus();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            return toast.error('Please fill out all the fields');
        }

        const response = await apiCall('POST', '/login', {email, password});

        if (response?.status === 401 || response?.status === 400) {
            return toast.error(response.message);
        } else if (response?.status === 200) {
            const user = {
                user: response.user,
                token: response.token
            }
            setUserData(user);
            setToken(response.token);

            return navigate('/dashboard');
        } else {
            toast.error('Login Failed');
        }
    }

    return (
        <div className="login">
            <form className="login__form" onSubmit={handleSubmit}>
                <input
                    ref={emailRef}
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    />
                <button
                    type="submit"
                    className="submit__btn" >Login</button>
                <div
                    className="signup__link">Not a member? <div onClick={() => setIsLogIn(false)}>Sign Up</div></div>
            </form>
            <Toaster />
        </div>
    )
}

export default Login;
